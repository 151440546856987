/* stylelint-disable scss/at-rule-no-unknown */
@tailwind base;
@tailwind components;
@tailwind utilities;

#__next,
html,
body {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
