.form-group {
  padding: 40px;
  border: 1px solid #f0f0f0;
}

.form-group:not(:last-child) {
  margin-bottom: 30px;
}

.form-group .form-group {
  border: none;
  border-top: 1px solid #f0f0f0;
}
