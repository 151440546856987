@import '../../style/themes/index';
@import '../../style/mixins/index';
@import '../../input/style/mixin';
@import '../../checkbox/style/mixin';

@cascader-prefix-cls: ~'@{ant-prefix}-cascader';

.antCheckboxFn(@checkbox-prefix-cls: ~'@{cascader-prefix-cls}-checkbox');

.@{cascader-prefix-cls} {
  width: 184px;

  &-checkbox {
    top: 0;
    margin-right: @padding-xs;
  }

  &-menus {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;

    &.@{cascader-prefix-cls}-menu-empty {
      .@{cascader-prefix-cls}-menu {
        width: 100%;
        height: auto;
      }
    }
  }

  &-menu {
    flex-grow: 1;
    min-width: 111px;
    height: 180px;
    margin: 0;
    margin: -@dropdown-edge-child-vertical-padding 0;
    padding: @cascader-dropdown-edge-child-vertical-padding 0;
    overflow: auto;
    vertical-align: top;
    list-style: none;
    border-right: @border-width-base @border-style-base @cascader-menu-border-color-split;
    -ms-overflow-style: -ms-autohiding-scrollbar; // https://github.com/ant-design/ant-design/issues/11857

    &-item {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding: @cascader-dropdown-vertical-padding @control-padding-horizontal;
      overflow: hidden;
      line-height: @cascader-dropdown-line-height;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background: @item-hover-bg;
      }

      &-disabled {
        color: @disabled-color;
        cursor: not-allowed;

        &:hover {
          background: transparent;
        }
      }

      .@{cascader-prefix-cls}-menu-empty & {
        color: @disabled-color;
        cursor: default;
        pointer-events: none;
      }

      &-active:not(&-disabled) {
        &,
        &:hover {
          font-weight: @select-item-selected-font-weight;
          background-color: @cascader-item-selected-bg;
        }
      }

      &-content {
        flex: auto;
      }

      &-expand &-expand-icon,
      &-loading-icon {
        margin-left: @padding-xss;
        color: @text-color-secondary;
        font-size: 10px;

        .@{cascader-prefix-cls}-menu-item-disabled& {
          color: @disabled-color;
        }
      }

      &-keyword {
        color: @highlight-color;
      }
    }
  }
}

@import './rtl';

@root-entry-name: variable;